import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  en: {
    account: "Username",
    password: "Password",
    login: "Login",
    cashHistory: "Cash History",
    betHistory: "Bet History",
    khmer: "Khmer",
    english: "English",
    china: "China",
    thai: "Thai",
    vietnam: "Vietnam",
    laos: "Laos",
    logOut: "LogOut",
    changePassword: "Change Password",
    newPassword: "New Password",
    enterNewPassword: "Enter New Password",
    submit: "Submit",
    submitting: "Submitting...",
    discard: "Discard",
    weakPassword: "Weak Password",
    moderatePassword: "Moderate Password",
    strongPassword: "Strong Password",
    lessPassword: "Password must be at least 8 characters",
    changedPassword: "Password changed successfully",
    passwordAccount: "Please enter your username",
    passwordRequired: "Please enter your password",
    allGame: "All Games",
    copyRight: "Copyright by.",
  },
  laos: {
    account: "ບັນຊີ",
    password: "ລະຫັດຜ່ານ",
    login: "ເຂົ້າສູ່ລະບົບ",
    cashHistory: "ປະຫວັດເງິນສົດ",
    betHistory: "ປະຫວັດການເດີມພັນ",
    khmer: "ຂະເໝນ",
    english: "ພາສາອັງກິດ",
    china: "ຈີນ",
    thai: "ໄທ",
    laos: "ລາວ",
    vietnam: "ຫວຽດນາມ",
    logOut: "ອອກ​ຈາກ​ລະ​ບົບ",
    changePassword: "ປ່ຽນ​ລະ​ຫັດ​ຜ່ານ",
    newPassword: "ລະ​ຫັດ​ຜ່ານ​ໃໝ່",
    enterNewPassword: "ປ້ອນ​ລະ​ຫັດ​ຜ່ານ​ໃໝ່",
    submit: "ສົ່ງ",
    submitting: "ກຳ​ລັງ​ສົ່ງ...",
    discard: "ຖິ້ມ",
    weakPassword: "ລະ​ຫັດ​ຜ່ານ​ຍ່ອຍ​ເກີນ",
    moderatePassword: "ລະ​ຫັດ​ຜ່ານ​ຍ່ອຍ​ກາຍ",
    strongPassword: "ລະ​ຫັດ​ຜ່ານ​ຍ່ອຍ​ສູງ",
    lessPassword: "ລະ​ຫັດ​ຜ່ານ​ຕ້ອງ​ບໍ່​ມີ​ໜ້ອຍ​ 8 ຕົວ​ອັກສອນ",
    changedPassword: "ປ່ຽນ​ລະ​ຫັດ​ຜ່ານ​ສຳ​ເລັດ",
    passwordAccount: "ກະ​ລຸ​ນາ​ປ້ອນ​ຊື່​ບັນ​ຊີ",
    passwordRequired: "ກະ​ລຸ​ນາ​ປ້ອນ​ລະ​ຫັດ​ຜ່ານ",
    allGame: "ເກມທັງໝົດ",
    copyRight: "ສະຫງວນລິຂະສິດໂດຍ.",
  },
  china: {
    account: "帳戶",
    password: "密碼",
    login: "登錄",
    cashHistory: "現金歷史",
    betHistory: "投注歷史",
    khmer: "高棉語",
    english: "英語",
    china: "中國",
    laos: "老撾",
    thai: "泰國",
    vietnam: "越南",
    logOut: "登出",
    changePassword: "更改密碼",
    newPassword: "新密碼",
    enterNewPassword: "輸入新密碼",
    submit: "提交",
    submitting: "提交中...",
    discard: "丢弃",
    weakPassword: "弱密碼",
    moderatePassword: "中等密碼",
    strongPassword: "強密碼",
    lessPassword: "密碼必須至少8個字符",
    changedPassword: "密碼更改成功",
    passwordAccount: "請輸入您的用戶名",
    passwordRequired: "請輸入您的密碼",
    allGame: "所有游戏",
    copyRight: "版权所有.",
  },
  viet: {
    account: "tài khoản",
    password: "mật khẩu",
    login: "đăng nhập",
    cashHistory: "Lịch sử tiền mặt",
    betHistory: "Lịch sử đặt cược",
    khmer: "khmer",
    english: "Tiếng Anh",
    china: "Trung Quốc",
    thai: "tiếng Thái",
    laos: "Nước Lào",
    vietnam: "Việt Nam",
    logOut: "Đăng xuất",
    changePassword: "Đổi mật khẩu",
    newPassword: "Mật khẩu mới",
    enterNewPassword: "Nhập mật khẩu mới",
    submit: "Gửi đi",
    submitting: "Đang gửi...",
    discard: "loại bỏ",
    weakPassword: "Mật khẩu yếu",
    moderatePassword: "Mật khẩu vừa phải",
    strongPassword: "Mật khẩu mạnh",
    lessPassword: "Mật khẩu phải có ít nhất 8 ký tự",
    changedPassword: "Đổi mật khẩu thành công",
    passwordAccount: "Vui lòng nhập tên người dùng của bạn",
    passwordRequired: "Vui lòng nhập mật khẩu của bạn",
    allGame: "Tất cả các trò chơi",
    copyRight: "bản quyền bởi.",
  },
  km: {
    account: "គណនី",
    password: "ពាក្យសម្ងាត់",
    login: "ចូលប្រើ",
    cashHistory: "ប្រវត្តិសាច់ប្រាក់",
    betHistory: "ប្រវត្តិភ្នាល់",
    khmer: "ខ្មែរ",
    english: "អង់គ្លេស",
    china: "ចិន",
    laos: "ឡាវ",
    thai: "ថៃ",
    vietnam: "វៀតណាម",
    logOut: "ចាកចេញ",
    changePassword: "ផ្លាស់ប្តូរពាក្យសម្ងាត់",
    newPassword: "ពាក្យសម្ងាត់ថ្មី",
    enterNewPassword: "បញ្ចូលពាក្យសម្ងាត់ថ្មី",
    submit: "ដាក់ស្នើ",
    submitting: "កំពុងដាក់ស្នើ...",
    discard: "បោះបង់",
    weakPassword: "ពាក្យសម្ងាត់ខ្សោយ",
    moderatePassword: "ពាក្យសម្ងាត់មធ្យម",
    strongPassword: "ពាក្យសម្ងាត់ខ្លាំង",
    lessPassword: "ពាក្យសម្ងាត់ត្រូវមានយ៉ាងហោចណាស់ 8 តួអក្សរ",
    changedPassword: "ពាក្យសម្ងាត់ត្រូវបានប្តូរជោគជ័យ",
    passwordAccount: "សូមបញ្ចូលឈ្មោះអ្នកប្រើរបស់អ្នក",
    passwordRequired: "សូមបញ្ចូលពាក្យសម្ងាត់របស់អ្នក",
    allGame: "ហ្គេមទាំងអស់",
    copyRight: "រក្សាសិទ្ធិដោយ៖",
  },
  thai: {
    account: "บัญชี",
    password: "รหัสผ่าน",
    login: "เข้าสู่ระบบ",
    cashHistory: "ประวัติเงินสด",
    betHistory: "ประวัติการเดิมพัน",
    khmer: "เขมร",
    english: "ภาษาอังกฤษ",
    china: "จีน",
    thai: "ภาษาไทย",
    vietnam: "เวียดนาม",
    logOut: "ออกจากระบบ",
    changePassword: "เปลี่ยนรหัสผ่าน",
    newPassword: "รหัสผ่านใหม่",
    enterNewPassword: "ป้อนรหัสผ่านใหม่",
    submit: "เสนอ",
    submitting: "กำลังส่ง...",
    discard: "ทิ้ง",
    weakPassword: "รหัสผ่านอ่อนแอ",
    moderatePassword: "รหัสผ่านปานกลาง",
    strongPassword: "รหัสผ่านแข็งแกร่ง",
    lessPassword: "รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร",
    changedPassword: "เปลี่ยนรหัสผ่านสำเร็จ",
    passwordAccount: "กรุณาใส่ชื่อผู้ใช้ของคุณ",
    passwordRequired: "กรุณาใส่รหัสผ่านของคุณ",
    allGame: "เกมส์ทั้งหมด",
    copyRight: "ลิขสิทธิ์โดย.",
  },
});

export default strings;
