import Template_1 from './template_1/App'
import Template_2 from './template_2/App'

type ThemeProps = {
  channel: BroadcastChannel
}
export default function Theme({ channel }: ThemeProps) {
  const theme = Number(import.meta.env.VITE_THEME_GAME);

  let RenderTheme: JSX.Element;

  switch (theme) {
    case 1:
      RenderTheme = <Template_1 channel={channel} />;
      break;
    case 2:
      RenderTheme = <Template_2 channel={channel} />;
      break;
    default:
      RenderTheme = <Template_1 channel={channel} />;
      break;
  }
  return (
    <>
      {RenderTheme}
    </>
  )
}