import React, { Component, Suspense } from "react";
import { createBrowserRouter, RouterProvider, useLocation, useNavigate, useParams, } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AccessKey } from "../../../enum";
import error from "../assets/svg/error.svg";
import Login from "../pages/Login";
import { innitLocalizedStrings } from "../util";
import loading from "../assets/svg/loading.svg";
import "../index.css"

const Home = React.lazy(() => import("../pages/Home"));
const Fallback = (): JSX.Element => (
	<div className="bg-secondary-color h-screen bg-no-repeat bg-cover flex flex-col w-screen items-center justify-center">
		<img src={import.meta.env.VITE_APP_LOGO_URL} className="h-1/3 max-sm:w-1/2 max-sm:h-auto object-contain"
			alt="logo" />
		<img src={loading} alt="loading" className="h-1/5" />
	</div>
);

function ErrorPage({ message }: { message: string }) {
	return (
		<div className="h-screen flex flex-col w-screen items-center justify-center gap-2">
			<img src={error} className="w-full md:w-1/2 h-1/4 md:h-1/2" />
			<p className="text-white w-4/5 font-normal md:font-medium text-center text-sm md:text-base">{message}</p>
		</div>
	);
}

function Router({ WrappedComponent, channel }: any) {
	const location = useLocation();
	const navigate = useNavigate();
	const params = useParams();
	return <WrappedComponent router={{ navigate, location, params }} channel={channel} />;
}
interface RoutesProps {
	channel: BroadcastChannel;
}
const channel = new BroadcastChannel(window.location.origin);
export default class Routes extends Component<RoutesProps> {

	state = {
		auth: false,
		loading: true
	};

	private readonly protectedRoute = createBrowserRouter([
		{
			path: "/",
			element: <Router WrappedComponent={Home} channel={this.props.channel} />,
			errorElement: (
				<ErrorPage message="Something went wrong, please contact support." />
			)
		}
	]);

	private tokenExpired = (token: string, next: Function) => {
		const mode = import.meta.env.MODE === 'production';
		fetch(mode ? import.meta.env.VITE_APP_SB_PROD + '/api/channel/getOwnChannels' : import.meta.env.VITE_APP_SB_DEV + '/api/channel/getOwnChannels', {
			method: 'GET',
			headers: {
				apiKey: import.meta.env.VITE_APP_APIKEY,
				Authorization: `Bearer ${token}`,
				clientIp: "",
				"Content-Type": "application/json"
			},
		}).then(res => res.json()).then(res => {
			if (res.code !== 200) {
				window.localStorage.clear();
				this.setState({ auth: false, loading: false });
			} else {
				next();
			}
		});
	};

	componentDidMount(): void {
		if (window.localStorage.getItem(AccessKey.TOKEN) !== null) {
			this.tokenExpired(window.localStorage.getItem(AccessKey.TOKEN) as string, () => {
				const time = setTimeout(() => {
					this.setState({ auth: true, loading: false });
					clearTimeout(time);
				}, 1000);
			});
		} else {
			this.setState({ auth: false, loading: false });
		}
		innitLocalizedStrings(window.localStorage.getItem(AccessKey.LANGUAGE));
		const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement('link');
		link.type = 'image/x-icon';
		link.rel = 'shortcut icon';
		link.href = import.meta.env.VITE_APP_ICON_URL;
		document.getElementsByTagName('head')[0].appendChild(link);
		// add meta description
		const meta: HTMLMetaElement = document.createElement('meta');
		meta.name = 'description';
		meta.content = import.meta.env.VITE_APP_DESCRIPTION;
		document.getElementsByTagName('head')[0].appendChild(meta);
		document.title = import.meta.env.VITE_APP_TITLE;
		document.body.classList.add("bg-secondary-color");
		channel.onmessage = (event) => {
			if (event.data === 'MAIN') {
				window.close();
			}
		};
	}

	// componentWillUnmount(): void {
	// 	channel.postMessage('MAIN');
	// }
	componentDidUpdate(): void {
		channel.postMessage('MAIN');
	}

	render() {
		if (this.state.loading) return <Fallback />;
		if (this.state.auth) return (
			<main className="w-screen">
				<ToastContainer />
				<Suspense fallback={<Fallback />}>
					<RouterProvider router={this.protectedRoute} />
				</Suspense>
			</main>
		);
		return <Login changeLanguage={(lang: string) => innitLocalizedStrings(lang)} />
	}
}