import React from 'react'
import ReactDOM from 'react-dom/client'
import Theme from './themes/'

const channel = new BroadcastChannel(window.location.origin);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Theme channel={channel} />
  </React.StrictMode>,
)
