import khmerFlag from "../assets/flag/cambodia.png";
import thaiFlag from "../assets/flag/thailand.png";
import china from "../assets/flag/china.png";
import laos from "../assets/flag/laos.png";
import vietnam from "../assets/flag/vietnam.png";
import english from "../assets/flag/united-kingdom.png";
import strings from "../internationalization";
import { AccessKey } from "../../../enum";

export function setLocalStore(key: string, value: string) {
  return new Promise((resolve, reject) => {
    window.localStorage.setItem(key, value);
    resolve("done");
    reject("error");
  });
}

export function getLocalStore(key: string): string | null {
  return window.localStorage.getItem(key);
}

type flagType = "khmer" | "english" | "china" | "thai" | "vietnam" | "laos";

export function getFlag(name: flagType) {
  let flag = "";
  switch (name) {
    case "khmer":
      flag = khmerFlag;
      break;
    case "english":
      flag = english;
      break;
    case "china":
      flag = china;
      break;
    case "thai":
      flag = thaiFlag;
      break;
    case "vietnam":
      flag = vietnam;
      break;
    case "laos":
      flag = laos;
      break;
  }
  return flag;
}


export function innitLocalizedStrings(key: string | null) {
  if (key) {
    strings.setLanguage(key);
    window.localStorage.setItem(AccessKey.LANGUAGE, key);
  } else strings.setLanguage("km");
}

export function getGames() {
  if (Object.keys(import.meta.env).length === 0) return []

  const list = Object.keys(import.meta.env).filter((key) => key.startsWith("VITE_APP_GAME_"));

  const groupedData: {
    [suffix: string]: string[]
  } = {};

  for (const item of list) {
    const [, suffix] = item.match(/_(\d+)$/) || [];
    if (suffix) {
      if (groupedData[suffix]) {
        groupedData[suffix].push(item);
      } else {
        groupedData[suffix] = [item];
      }
    }
  }

  const result: string[][] = Object.values(groupedData);
  const data = []

  for (let i = 0; i < result.length; i++) {
    const chunk = result[i];
    const obj: any = {};
    for (let j = 0; j < chunk.length; j++) {
      const key = chunk[j];
      if (key.startsWith('VITE_APP_GAME_CHANNEL_URL_')) {
        obj['url'] = import.meta.env[key];
      }
      if (key.startsWith('VITE_APP_GAME_NAME_')) {
        obj['name'] = import.meta.env[key];
      }
      if (key.startsWith('VITE_APP_GAME_IMAGE_URL_')) {
        obj['image'] = import.meta.env[key];
      }
      if (key.startsWith('VITE_APP_GAME_COMING_SOON_')) {
        obj['isComingSoon'] = import.meta.env[key] === 'true';
      }
    }
    data.push(obj);
  }

  return data;
}