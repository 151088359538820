import { LegacyRef, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { AccessKey } from '../../../enum';
import FooterTeal from '../assets/svg/footer-teal.svg';
import FooterWhite from '../assets/svg/footer.svg';
import submitIcon from '../assets/svg/submit.svg';
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa6";

const Login = () => {
	const usernameRef: LegacyRef<HTMLInputElement> | null = useRef(null);
	const passwordRef: LegacyRef<HTMLInputElement> | null = useRef(null);
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [message, setMessage] = useState<string>('');
	const [requiredUserName, setRequiredUsername] = useState<boolean>(false);
	const [requiredPassword, setRequiredPassword] = useState<boolean>(false);
	const [isProcessing, setIsProcessing] = useState<boolean>(false);

	const handleLogin = async () => {

		if (usernameRef === null || passwordRef === null) return;

		setMessage('')
		const username = usernameRef.current?.value;
		const password = passwordRef.current?.value;
		const data = {
			username,
			password
		}
		if (!username || !password) {
			if (!username) setRequiredUsername(true);
			if (!password) setRequiredPassword(true);
			return;
		}
		setIsProcessing(true);
		const mode = import.meta.env.MODE === 'production';
		const response = await fetch(mode ? import.meta.env.VITE_APP_SB_PROD + '/api/player/login' : import.meta.env.VITE_APP_SB_DEV + '/api/player/login', {
			method: 'POST',
			headers: {
				apiKey: import.meta.env.VITE_APP_APIKEY,
				clientIp: "",
				"Content-Type": "application/json"
			},
			body: JSON.stringify(data)
		}).then(res => res.json());
		if ("data" in response) {
			window.localStorage.setItem(AccessKey.TOKEN, response.data.token);
			window.localStorage.setItem(AccessKey.USERNAME, response.data.username);
			window.location.reload();
		} else {
			setMessage(response.error);
		}
		setIsProcessing(false);
	}

	const isMobile = useMediaQuery({ query: '(max-width: 640px)' })
	return (
		<>
			{
				isMobile
					?
					<div className="flex flex-wrap items-center justify-center h-screen bg-third-color login-mobile fixed">
						<div className="login flex h-screen w-screen justify-center bg-cover-mobile bg-no-repeat bg-cover">
							<div className="h-full flex flex-col w-full items-center justify-start relative z-20">
								<img src={import.meta.env.VITE_APP_LOGO_URL} alt="logo" className="w-[110px] mt-20" />
								<div className="flex items-center relative mt-14 w-4/5">
									<svg className="w-8 h-8 absolute left-3 fill-white top-5"
										xmlns="http://www.w3.org/2000/svg" width="50" height="49" viewBox="0 0 50 49"
										fill="none">
										<path fillRule="evenodd" clipRule="evenodd"
											d="M49.8806 48.1676H0.634535C0.634535 48.1676 -0.422699 41.1143 3.92555 38.0306C8.2738 34.9469 14.9412 36.5825 19.1018 31.0795C19.1018 31.0795 20.6536 29.3417 17.175 25.0825C13.6964 20.8403 13.4065 14.0766 14.2761 8.86331C15.1458 3.64999 20.0738 0.174438 25.1894 0.174438H25.3428C30.4584 0.174438 35.4035 3.64999 36.2732 8.86331C37.1428 14.0766 36.8529 20.8403 33.3743 25.0825C29.8957 29.3417 31.4475 31.0795 31.4475 31.0795C35.6081 36.5825 42.2755 34.9469 46.6237 38.0306C50.9379 41.1143 49.8806 48.1676 49.8806 48.1676Z" />
									</svg>

									<input
										type="text"
										ref={usernameRef}
										onKeyPress={e => e.key === 'Enter' && handleLogin()}
										onChange={() => setRequiredUsername(false)}
										className="background-input bg-transparent pl-14 w-full mt-2 h-14 text-white focus:outline-none focus:ring-0 font-normal text-base placeholder-white border-transparent focus:border-transparent"
										placeholder={"ឈ្មោះគណនី"} style={{ fontFamily: "Krasar-regular" }} />
									<div
										className={`border-b-2 w-full absolute -bottom-2 ${requiredUserName ? 'border-red-500' : 'border-white'}`} />

									{
										requiredUserName && <span
											className="absolute text-red-500 text-xs -bottom-8">ឈ្មោះគណនីមិនអាចទទេ</span>
									}
								</div>
								<div className="flex items-center mt-8 relative w-4/5">
									<svg className="w-8 h-8 absolute left-3 fill-white top-5"
										xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53"
										fill="none">
										<path
											d="M26.9445 0.545258C12.5527 0.545258 0.88916 12.1986 0.88916 26.5778C0.88916 40.957 12.5527 52.6103 26.9445 52.6103C41.3364 52.6103 53 40.957 53 26.5778C53 12.1986 41.3364 0.545258 26.9445 0.545258ZM18.3674 18.5193C18.3674 13.8001 22.2042 9.96671 26.9275 9.96671C31.6509 9.96671 35.4876 13.8001 35.4876 18.5193V25.5555H32.0772V18.5193C32.0772 15.6741 29.7752 13.3741 26.9275 13.3741C24.0799 13.3741 21.7778 15.6741 21.7778 18.5193V25.5555H18.3674V18.5193ZM38.8639 40.3948H15.0252V36.6296H38.8639V40.3948ZM38.8639 35.42H15.0252V31.6548H38.8639V35.42ZM38.8639 30.4452H15.0252V26.68H38.8639V30.4452Z" />
									</svg>
									<input
										type={showPassword ? "text" : "password"}
										ref={passwordRef}
										onKeyPress={e => e.key === 'Enter' && handleLogin()}
										onChange={() => setRequiredPassword(false)}
										className="background-input bg-transparent px-14 w-full mt-2 h-14 text-white focus:outline-none focus:ring-0 font-normal text-base placeholder-white border-transparent focus:border-transparent"
										placeholder={"លេខសម្ងាត់"} style={{ fontFamily: "Krasar-regular" }} />
									<span className="absolute right-3 top-6 cursor-pointer"
										onClick={() => setShowPassword(!showPassword)}>
										{
											showPassword ? <FaRegEye className="text-2xl text-white" /> : <FaRegEyeSlash className="text-2xl text-white" />
										}
									</span>
									<div
										className={`border-b-2 w-full absolute -bottom-2 ${requiredPassword ? 'border-red-500' : 'border-white'}`} />
									{
										requiredPassword && <span
											className="absolute text-red-500 text-xs -bottom-8">លេខសម្ងាត់មិនអាចទទេ</span>
									}
								</div>
								<div className="flex justify-start items-start mt-10 w-4/5">
									<div className="flex items-center justify-center">
										<input type="checkbox" id="remember-mobile"
											className="border-2 border-third-color rounded-md p-2 outline-none focus:outline-none focus:border-secondary-color focus:ring-0 checked:bg-secondary-color checked:border-secondary-color checked:ring-0" />
										<label
											htmlFor="remember-mobile"
											className="text-base font-normal ml-2 text-white"
											style={{ fontFamily: "Krasar-bold" }}>រក្សាគណនីទុក </label>
									</div>
								</div>
								<div className="flex justify-center items-center mt-10 relative">
									<button
										className="bg-transparent w-28 h-14 absolute top-0 text-lg text-white focus:outline-none focus:border-transparent focus:ring-0 font-bold text-center"
										style={{ fontFamily: "Krasar-bold" }}
										onClick={handleLogin}
									>
										ចុចចូល
									</button>
									<img src={submitIcon} alt="submit" className="w-28 cursor-pointer" />
								</div>
								<div className="mt-4 relative flex justify-center w-3/4">
									{
										message &&
										<span
											className="absolute  app-font-regular text-white bg-red-500 p-3 rounded-md">{message}</span>
									}
								</div>
							</div>
							<img src={import.meta.env.VITE_APP_LOTTO_BOX_URL} alt="lotto" className="w-2/3 bottom-0 max-w-xs:hidden fixed" />
							<img src={FooterWhite} alt="footer" className="fixed w-full bottom-0 z-2" />
							<img src={FooterTeal} alt="footer" className="fixed w-full bottom-0 z-2" />
						</div>
					</div>
					:
					<div className="flex flex-wrap items-center justify-center h-screen bg-third-color login-container">
						<div className=" flex w-screen justify-center items-center bg-cover-desktop bg-no-repeat bg-cover h-5/6">
							<div
								className="login-box flex justify-center items-center my-20 max-md:my-10 mx-3 rounded-3xl bg-secondary-color/60 border-4 border-third-color/30 xl:w-3/5 lg:w-10/12">
								<div
									className="bg-transparent overflow-hidden w-3/5 h-full flex flex-col items-center justify-center left-container">
									<img src={import.meta.env.VITE_APP_LOTTO_BOX_URL} alt="lotto" className="w-3/5" />
								</div>
								<div
									className="bg-white w-2/5 h-full rounded-r-3xl flex flex-col items-center justify-start overflow-hidden min-xl:w-full min-xl:rounded-3xl right-container">
									<img src={import.meta.env.VITE_APP_LOGO_URL} alt="logo"
										className="w-[150px] mt-10  lg:w-[180px] md:w-[150px]" />
									<div className="mt-5 w-full p-10">
										<h1 className="text-2xl text-secondary-color"
											style={{ fontFamily: "Krasar-bold" }}>ចូលលេង</h1>
										<div className="flex flex-col mt-5">
											<div className="flex items-center relative">
												<svg className="w-8 h-8 absolute left-3 fill-secondary-color top-5"
													xmlns="http://www.w3.org/2000/svg" width="50" height="49"
													viewBox="0 0 50 49" fill="none">
													<path fillRule="evenodd" clipRule="evenodd"
														d="M49.8806 48.1676H0.634535C0.634535 48.1676 -0.422699 41.1143 3.92555 38.0306C8.2738 34.9469 14.9412 36.5825 19.1018 31.0795C19.1018 31.0795 20.6536 29.3417 17.175 25.0825C13.6964 20.8403 13.4065 14.0766 14.2761 8.86331C15.1458 3.64999 20.0738 0.174438 25.1894 0.174438H25.3428C30.4584 0.174438 35.4035 3.64999 36.2732 8.86331C37.1428 14.0766 36.8529 20.8403 33.3743 25.0825C29.8957 29.3417 31.4475 31.0795 31.4475 31.0795C35.6081 36.5825 42.2755 34.9469 46.6237 38.0306C50.9379 41.1143 49.8806 48.1676 49.8806 48.1676Z" />
												</svg>
												<input
													type="text"
													ref={usernameRef}
													onKeyPress={e => e.key === 'Enter' && handleLogin()}
													onChange={() => setRequiredUsername(false)}
													className={`bg-input rounded-xl pl-14 w-full mt-2 h-14 text-secondary-color border focus:outline-none focus:ring-0 font-normal text-base placeholder-secondary-color ${requiredUserName ? 'border-red-500 focus:border-red-500 ' : 'border-input-2 focus:border-input-2'}`}
													placeholder={"ឈ្មោះគណនី"} style={{ fontFamily: "Krasar-regular" }} />
												{
													requiredUserName && <span
														className="absolute text-red-500 text-xs -bottom-5">ឈ្មោះគណនីមិនអាចទទេ</span>
												}
											</div>
											<div className="flex items-center mt-5 relative">
												<svg className="w-8 h-8 absolute left-3 fill-secondary-color top-5"
													xmlns="http://www.w3.org/2000/svg" width="53" height="53"
													viewBox="0 0 53 53" fill="none">
													<path
														d="M26.9445 0.545258C12.5527 0.545258 0.88916 12.1986 0.88916 26.5778C0.88916 40.957 12.5527 52.6103 26.9445 52.6103C41.3364 52.6103 53 40.957 53 26.5778C53 12.1986 41.3364 0.545258 26.9445 0.545258ZM18.3674 18.5193C18.3674 13.8001 22.2042 9.96671 26.9275 9.96671C31.6509 9.96671 35.4876 13.8001 35.4876 18.5193V25.5555H32.0772V18.5193C32.0772 15.6741 29.7752 13.3741 26.9275 13.3741C24.0799 13.3741 21.7778 15.6741 21.7778 18.5193V25.5555H18.3674V18.5193ZM38.8639 40.3948H15.0252V36.6296H38.8639V40.3948ZM38.8639 35.42H15.0252V31.6548H38.8639V35.42ZM38.8639 30.4452H15.0252V26.68H38.8639V30.4452Z" />
												</svg>
												<input
													type={showPassword ? "text" : "password"}
													ref={passwordRef}
													onKeyPress={e => e.key === 'Enter' && handleLogin()}
													onChange={() => setRequiredPassword(false)}
													className={`bg-input rounded-xl px-14 w-full mt-2 h-14 text-secondary-color border focus:outline-none  focus:ring-0 font-normal text-base placeholder-secondary-color ${requiredPassword ? 'border-red-500 focus:border-red-500 ' : 'border-input-2 focus:border-input-2'}`}
													placeholder={"លេខសម្ងាត់"} style={{ fontFamily: "Krasar-regular" }} />
												<span className="absolute right-3 top-6 cursor-pointer"
													onClick={() => setShowPassword(!showPassword)}>
													{
														showPassword ? <FaRegEye className="text-2xl text-secondary-color" /> : <FaRegEyeSlash className="text-2xl text-secondary-color" />
													}
												</span>
												{
													requiredPassword && <span
														className="absolute text-red-500 text-xs -bottom-5">លេខសម្ងាត់មិនអាចទទេ</span>
												}
											</div>

											<div className="flex justify-between items-center mt-7">
												<div className="flex items-center justify-center">
													<input type="checkbox" id="remember"
														className="border-2 border-secondary-color rounded-md p-2 outline-none focus:outline-none focus:border-secondary-color focus:ring-0 checked:bg-secondary-color checked:border-secondary-color checked:ring-0" />
													<label
														htmlFor="remember"
														className="text-base font-normal ml-2 text-secondary-color"
														style={{ fontFamily: "Krasar-bold" }}>រក្សាគណនីទុក </label>
												</div>
											</div>
										</div>
										<div className="flex justify-center items-center mt-5 relative">
											<button
												className="bg-transparent w-40 h-16 absolute top-1 text-xl text-secondary-color focus:outline-none focus:border-transparent focus:ring-0 font-bold"
												style={{ fontFamily: "Krasar-bold" }}
												onClick={handleLogin}
											>
												{
													isProcessing &&
													<span className="absolute left-5">
														<svg aria-hidden="true" className="w-6 h-6 mr-2 text-gray-200 animate-spin fill-primary-color" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg>
													</span>
												}
												ចុចចូល
											</button>
											<img src={submitIcon} alt="submit" className="w-40 cursor-pointer" />
										</div>
										<div className="mt-4 relative flex justify-center">
											{
												message &&
												<span
													className="absolute  app-font-regular text-red-700">{message}</span>
											}
										</div>
									</div>
								</div>

							</div>

						</div>
					</div>

			}
		</>
	)
}

export default Login